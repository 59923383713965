import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Libraries
import { motion, AnimatePresence } from 'framer-motion'

// Images
import Chevron from 'img/roundchevron.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

interface QuestionsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Questions: React.FC<QuestionsProps> = ({ fields }) => {
  const {
    allWpVraag,
    allWpVraagtype,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.questionsQueryQuery>(graphql`
    query questionsQuery {
      allWpVraag(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalVraagFragment
          }
        }
      }
      allWpVraagtype {
        edges {
          node {
            slug
            name
            vragen {
              nodes {
                ...generalVraagFragment
              }
            }
          }
        }
      }
    }
  `)

  const categories: any = allWpVraagtype.edges
  const posts: unknown = allWpVraag.edges
  const postIds = fields.posts?.map((post) => post?.databaseId)

  return (
    <section>
      <BlogBink
        showIds={postIds as Array<number>}
        posts={posts as BlogBinkPosts}
        categories={categories}
        id="questions"
        limit={Infinity}
      >
        <div>
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Label = styled.div`
  font-size: 28px;
  line-height: 36px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding-bottom: 25px;

  @media (max-width: 991px) {
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 10px;
  }
`

const BlogGrid: React.FC<BlogGridProps> = () => {
  const blogBink = useBlogBink()

  return (
    <>
      <div className="container">
        <div className="row justify-content-center g-lg-5 g-3 d-flex">
          {/* @ts-ignore */}
          {blogBink.categories.map((category: any, index: number) => {
            return (
              <div key={index} className="col-xl-9 col-lg-10">
                <Label>{category.node.name}</Label>
                {category.node.vragen.nodes.map((post: any) => (
                  <div key={post.id} className="mb-3">
                    <BlogGridPost node={post} />
                  </div>
                ))}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

interface BlogGridPostProps {
  node: GatsbyTypes.WpVraag
}

const StyledBlogGridPost = styled(motion.div)`
  background-color: ${({ theme }) => theme.color.grey};
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
`

const Question = styled.div<{ open: boolean }>`
  padding: 20px 35px;
  z-index: 3;
  transition: all 0.2s ease-in-out;

  & > div {
    color: ${({ theme }) => theme.color.dark};
    font-size: 16px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & svg {
    transition: all 0.2s ease-out;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${({ open }) =>
    open
      ? css`
          & svg {
            transform: rotate(90deg);
          }
        `
      : css`
          & svg {
            transform: rotate(0deg);
          }
        `}

  @media (max-width: 575px) {
    padding: 15px 15px;

    & svg {
      height: 30px;
      width: 30px;
      display: block;
    }
  }
`

const Answer = styled(motion.div)`
  cursor: default;
`

const InnerAnswer = styled.div`
  @media (min-width: 576px) {
    padding: 20px 35px 20px 35px;
  }

  @media (max-width: 575px) {
    padding: 10px 15px 10px 15px;
  }
`

const QuestionIcon = styled.div`
  width: 32px;
  height: 32px;
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <StyledBlogGridPost initial="init">
      <Question
        open={open}
        onClick={() => setOpen(!open)}
        className="d-flex justify-content-between align-items-center"
      >
        <div className="me-sm-0 me-2">{node.faq?.question}</div>
        <QuestionIcon>
          <Chevron />
        </QuestionIcon>
      </Question>
      <AnimatePresence>
        {open && (
          <Answer
            initial={{ height: 0 }}
            animate={{ height: 'min-content' }}
            exit={{ height: 0 }}
          >
            <InnerAnswer>
              <ParseContent content={node.faq?.answer || ''} />
            </InnerAnswer>
          </Answer>
        )}
      </AnimatePresence>
    </StyledBlogGridPost>
  )
}

export default Questions
